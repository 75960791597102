.btn-custom {
  background-color: rgb(248, 249, 250) !important;
  color: var(--theme-primary) !important;
  cursor: pointer !important;
  font-weight: 500;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

.btn-custom:hover {
  background-color: var(--theme-primary) !important;
  color: rgb(255, 255, 255) !important;
}

.mega-dropdown {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: absolute;
  top: 103%;
  /* left: 0; */
  width: auto;
  min-width: 250px;
  z-index: 1000;
}

.dropdown-menu-wrapper {
  display: flex;
  gap: 15px;
  padding: 10px;
}

.dropdown-column {
  min-width: 200px;
  border-right: 1px solid #ddd;
  padding: 10px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.dropdown-item.active {
  background-color: var(--theme-primary) !important;
  color: #fff !important;
}

.dropdown-item:hover {
  background-color: var(--theme-primary) !important;
  color: #fff !important;
}

/* Product Images Section (Below categories) */
.dropdown-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.product-item {
  text-align: center;
  margin: 10px;
}

.product-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.product-item h6 {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
