:root {
  --theme-primary: #677425;
  --color-white: #fff;
}

* {
  padding: 0;
  margin: 0;
}

.bg-grean-theme {
  background-color: var(--theme-primary);
}

.text-theme {
  color: var(--theme-primary);
}

.checkout-points {
  border-radius: 20px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #f9f9f9;
}

.banner-image {
  /* height: 100%; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  background-size: contain;
}

.btn-stylish {
  background-color: var(--theme-primary);
  color: var(--color-white);
  border: none;
  border-radius: 20px;
  font-weight: 300;
  font-size: 15px;
  line-height: 0;
  height: 40px;
  transition: background-color 0.3s, transform 0.2s;
}

.btn-stylish:hover {
  background-color: var(--theme-primary); /* Darker shade for hover */
  transform: scale(1.05); /* Slightly increase size on hover */
}

.btn-stylish:active {
  transform: scale(0.95); /* Slightly decrease size on click */
}

/* Product Details page css */

.h-fix-cardBody p {
  height: 90px; /* Adjust to your desired fixed height */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Optional: Show '...' for overflowed content */
  display: -webkit-box; /* For multi-line truncation */
  -webkit-line-clamp: 3; /* Optional: Limit to 3 lines */
  -webkit-box-orient: vertical;
}

.border-rad-none {
  border-radius: 0 !important;
}

.product-detail-container {
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.product-image img {
  width: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-info h2 {
  /* font-size: 24px; */
  margin-bottom: 5px;
}

.price {
  color: #b12704; /* Example for a price color */
  font-size: 20px;
  margin-bottom: 20px;
}

.options {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.quantity-btn {
  padding: 2px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.add-to-cart {
  background-color: var(--theme-primary);
  color: var(--color-white);
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* width: 20%; */
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #218838;
}

.related-products {
  margin-top: 40px;
}

.tabs {
  /* display: flex; */
  border-bottom: 2px solid #ccc;
  margin-top: 20px;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border: none;
  font-weight: bold;
  transition: color 0.3s;
}

.tab:hover {
  color: var(--theme-primary); /* Change color on hover */
}

.tab.active {
  /* border-bottom: 2px solid #007bff; Highlight active tab */
  color: var(--theme-primary); /* Change color for active tab */
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
  /* border: 2px solid var(--theme-primary); */
}

.margin-cart-main {
  margin-top: 100px;
}

.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.input-field {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
}

.radio-label {
  display: block;
  margin: 10px 0;
  font-size: 1rem;
}

.radio-input {
  margin-right: 10px;
}

.order-summary {
  padding: 15px;
  background-color: var(--color-white);
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 1rem;
}

.faq-heading {
  background-color: var(--theme-primary);
  margin-top: 70px;
  padding: 70px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.faq-heading h1 {
  color: #fffff2;
  font-weight: 700;
}

.faq-heading p {
  color: #fffff2;
  font-weight: 400;
}

.faqs {
  font-family: Arial, sans-serif;
  margin-bottom: 70px;
}

.faq-categories {
  margin-bottom: 20px;
}

.faq-categories button {
  background: none;
  border: none;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.faq-categories button.active {
  border-bottom: 2px solid var(--theme-primary);
  font-weight: bold;
}

.faq-item {
  border-bottom: 1px solid var(--theme-primary);
  padding: 8px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.faq-question h5 {
  font-weight: 600;
}

.faq-answer {
  margin-top: 5px;
}

.margin-contact-main {
  margin-top: 50px;
}

.heading-contact {
  font-size: 2.5rem;
  font-weight: 900;
}

.para-contact {
  font-family: "Roboto", sans-serif;
  line-height: 1.9;
  color: #8c8c8c;
}

.bg-colour {
  background-color: var(--theme-primary);
  border-radius: 5px;
}

.contact-item .icon {
  margin-bottom: 10px;
  padding-right: 10px;
}

.contact-item {
  color: var(--color-white);
}

.contact-item p {
  margin: 0;
  color: var(--color-white);
}

.contact-item a {
  text-decoration: none;
  color: var(--color-white);
}

/* About Us page styling */

/* General Page Styling */
.about-us {
  padding: 60px 20px;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 1.8;
  background-color: #f9fafc;
}

/* h1, h2 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
} */

/* p {
  font-size: 18px;
  margin-bottom: 20px;
} */

/* strong {
  color: #2980b9;
} */

/* Welcome Section */
.welcome-section {
  background: #ecf0f1;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  text-align: center;
}

.welcome-section p {
  font-size: 20px;
  font-weight: 300;
}

/* Product Range Section */
.product-range {
  margin-bottom: 60px;
}

.product-categories {
  display: flex;
  justify-content: space-around;
  gap: 30px;
}

.category {
  width: 30%;
  padding: 30px;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
}

.category:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.category h3 {
  color: #27ae60;
  margin-bottom: 20px;
}

.category p {
  font-size: 16px;
  color: #7f8c8d;
}

/* Why Choose Us Section */
.why-choose-us {
  margin-bottom: 60px;
}

.why-choose-us ul {
  list-style-type: none;
  padding: 0;
}

.why-choose-us li {
  background-color: var(--color-white);
  padding: 20px;
  margin-bottom: 20px;
  border-left: 8px solid #3498db;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.why-choose-us li strong {
  font-weight: bold;
  color: #3498db;
}

.why-choose-us li:hover {
  background-color: #ecf0f1;
}

/* Our Promise Section */
.our-promise {
  background-color: #e74c3c;
  color: var(--color-white);
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 60px;
}

.our-promise p {
  font-size: 20px;
  line-height: 1.6;
}

.our-promise strong {
  color: #ffeb3b;
}

/* Get in Touch Section */
.get-in-touch {
  background-color: #2c3e50;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  color: #ecf0f1;
}

.get-in-touch p {
  font-size: 18px;
}

.get-in-touch a {
  color: #ecdbff;
  text-decoration: underline;
  font-weight: bold;
}

.get-in-touch a:hover {
  color: var(--color-white);
  text-decoration: none;
}

.position-liked-head {
  position: absolute;
  top: 90px;
  z-index: 1;
}

@media (max-width: 991px) {
  .position-liked-head {
    top: 100px !important;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-heading {
    padding: 60px 35px;
  }
  .banner-main {
    --bs-aspect-ratio: 60vh !important;
    max-height: 630px !important;
  }

  .h-fix-cardBody p {
    height: auto;
  }

  .product-categories {
    flex-direction: column;
    align-items: center;
  }

  .category {
    width: 80%;
    margin-bottom: 20px;
  }
}

/* Preloader style */

.spinner-overlay {
  position: fixed; /* Use fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

/* add overlay when models are open */

.overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: 999; /* Make sure it’s above other elements */
}

/* Header Search Bar Styling */

.search-bar-container {
  border: 2px solid #0d6efd; /* Primary Bootstrap color */
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
}

.search-bar-container .form-control:focus,
.search-bar-container .form-select:focus {
  box-shadow: none; /* Remove focus glow for clean design */
}

.search-bar-container .form-select {
  max-width: 150px; /* Restrict select box width for balance */
}

.category-select {
  padding: 0 8px;
  height: 100%;
  border: none;
  background-color: #f3f3f3;
  font-size: 14px;
}

.search-input {
  flex-grow: 1;
  border: none;
  padding: 0 10px;
  font-size: 14px;
  height: 100%;
}

.search-button {
  background-color: var(--theme-primary);
  color: #fff;
  border: none;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.blinking-dot-detail {
  color: green;
  animation: blink 1.5s infinite;
}

.blinking-dot-detail-out-of-stock {
  color: rgb(221, 6, 6);
  animation: blink 1.5s infinite;
}

.font-bold-star {
  font-size: large;
  font-weight: 900;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Product-details-points {
  border-radius: 20px;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  text-align: center;
  color: #f9f9f9;
  background-color: var(--theme-primary);
}

/* Zoom effect on product image in ProductDetails page */

.image-container {
  position: relative;
  overflow: hidden; /* Prevents overflow when zooming */
  width: 100%;
}

.zoom-image {
  transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}

.zoom-image:hover {
  transform: scale(1.2); /* Scale the image on hover */
}

/* slider Model start */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-custom {
  position: relative;
  width: 50%;
  height: 50%;
  /* max-width: 500px; */
  background: white;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.image-slider {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.arrow {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.image-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px;
  height: 10px;
  background: gray;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background: black;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
  }

  .modal-content-custom {
    width: 100%;
    margin: 0 10px;
  }
}

/* slider Model end */

/* Add To Cart Button Spinner */
.spin {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* slider side indicator button display none */

/* Custom styles to hide arrows in react-slick */
.slick-prev,
.slick-next {
  display: none !important; /* Ensure arrows are completely hidden */
}

/* End Slider Side Button */

.highlight {
  /* background-color: yellow;  */
  color: red;
}

.eye-position {
  position: absolute;
  bottom: -4px;
  right: 6px;
}

.size-option.selected {
  background-color: rgb(103, 116, 37);
  color: white !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.search-bar-mobile-container {
  position: fixed;
  z-index: 2;
  top: 100px;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  height: 55px;
  width: 100vw;
  max-width: 100%;
}

.search-bar-mobile-container-new {
  height: 50px;
  margin-top: 15px;
}

.banner-main {
  --bs-aspect-ratio: 100vh;
  max-height: 630px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #333;
}

.swiper-pagination-bullet {
  background-color: #666;
}

.swiper-pagination-bullet-active {
  background-color: #000;
}

.variant-title {
  font-weight: bold;
  border: 2px solid var(--theme-primary);
  padding: 3px;
  font-size: 0.9rem;
  margin-left: 5px;
  display: inline;
}

.equal-height-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 480px;
}

.price-badge {
  z-index: 10;
}

.footer-links-color a {
  color: #fff !important;
  text-decoration: none !important;
}

.footer-links-color a:hover {
  font-weight: 600;
}

.btn-green-theme {
  color: var(--theme-primary) !important;
  border-color: var(--theme-primary) !important;
  font-weight: 600 !important;
}
.btn-green-theme:hover {
  color: var(--color-white) !important;
  background-color: var(--theme-primary) !important;
  border-color: var(--theme-primary) !important;
}

.mobile-menu .nav-item {
  border-bottom: 1px dashed;
}
