.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f4f7fa;
  text-align: center;
}

.notfound-svg {
  width: 200px;
  height: 200px;
  animation: pulse 1.5s infinite ease-in-out;
}

.notfound-text {
  font-size: 120px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  fill: transparent;
  stroke-linejoin: round;
  stroke-dasharray: 5, 5;
  animation: blink 1.5s infinite;
}

.notfound-title {
  font-size: 48px;
  color: #333;
  font-family: "Arial", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.notfound-description {
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
  font-family: "Arial", sans-serif;
}

.notfound-button {
  background-color: var(--theme-primary);
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 25px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notfound-button:hover {
  background-color: #fff;
  color: var(--theme-primary);
  border: 1px solid var(--theme-primary);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    stroke-dasharray: 5, 5;
  }
  50% {
    stroke-dasharray: 10, 5;
  }
  100% {
    stroke-dasharray: 5, 5;
  }
}
